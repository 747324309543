<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12>
        <scan-progress-panel :value="tickCounter / 6"></scan-progress-panel>
      </v-flex>
      <v-flex xs4>
        <contamination-level-panel
          :contaminationLevel="contaminationLevel"
        ></contamination-level-panel>
      </v-flex>
      <v-flex xs4>
        <contamination-rate-panel
          :contaminationRate="contaminationRate"
        ></contamination-rate-panel>
      </v-flex>
      <v-flex xs4>
        <events-panel></events-panel>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ScanProgressPanel from "@/components/scan-progress-panel.vue";
import ContaminationLevelPanel from "@/components/contamination-level-panel.vue";
import ContaminationRatePanel from "@/components/contamination-rate-panel.vue";
import EventsPanel from "@/components/events-panel.vue";

@Component({
  name: "HomeView",
  components: {
    ScanProgressPanel,
    ContaminationLevelPanel,
    ContaminationRatePanel,
    EventsPanel,
  },
})
export default class HomeView extends Vue {
  selectedTab = 0;
  slideInterval: number | null = null;
  tickCounter = 0;

  contaminationLevel = 0;
  contaminationRate = 0;
  parasitesCount = 1;
  durationInMimutes = 0;

  diminishingImpact(x: number) {
    const A = 7; // The difference between y(1) and the asymptote y(10)
    const k = 0.5; // Adjust this for how quickly the curve decays
    const C = 1; // Asymptote, y(10)

    return A * Math.exp(-k * (x - 1)) + C;
  }

  calculateIncrement(y: number) {
    const totalMinutes = y * 60;
    const incrementPerMinute = 100 / totalMinutes;
    return incrementPerMinute;
  }

  mounted() {
    this.slideInterval = setInterval(this.tick, 100);
  }

  tick() {
    this.tickCounter++;

    // update every minute
    if (this.tickCounter >= 600) {
      // update duration
      this.durationInMimutes += 1;

      //  every 5 minutes add 1 parasite
      if (this.durationInMimutes % 5 === 0) {
        this.parasitesCount += 1;
      }
      console.log(
        "🚀 ~ HomeView ~ tick ~ this.parasitesCount:",
        this.parasitesCount
      );

      // calculate contamination rate based on number of parasites
      let expectedDuration = this.diminishingImpact(this.parasitesCount);

      // calculate contamination rate
      this.contaminationRate = this.calculateIncrement(expectedDuration);

      this.contaminationLevel += this.contaminationRate;

      // reset tick counter
      this.tickCounter = 0;
    }
  }

  beforeDestroy() {
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
    }
  }
}
</script>

<style scoped></style>
