<template>
  <v-card height="100%">
    <v-card-title> Contamination rate </v-card-title>
    <v-card-text>
      <div class="center mt-12">
        <h1 class="blue--text">+{{ contaminationRate.toFixed(2) }}/m</h1>
      </div>
      <div class="center">
        <span class="orange--text" style="font-size: smaller"
          >Estimated time to 80%: 1h 10m</span
        >
      </div>
      <div class="center">
        <span class="red--text" style="font-size: smaller"
          >Estimated time to 80%: 2h 5m</span
        >
      </div>
      <v-sparkline
        class="mt-12"
        :value="previousRates"
        :gradient="gradient"
        auto-draw
      ></v-sparkline>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "ContaminationRatePanel",
})
export default class ContaminationRatePanel extends Vue {
  @Prop({ required: true })
  contaminationRate!: number;

  get contaminationRateAdjusted() {
    return this.contaminationRate * 20;
  }

  previousRates: number[] = [
    0.2054, 0.2129, 0.2088, 0.2089, 0.2122, 0.2099, 0.2045, 0.2098, 0.2115,
    0.2056, 0.2083, 0.2045, 0.2061, 0.2064, 0.2119, 0.2035, 0.2038, 0.2048,
    0.2061, 0.2067, 0.2121, 0.2122, 0.2084, 0.2084, 0.2121, 0.2081, 0.2079,
    0.211, 0.2043, 0.2035, 0.3219, 0.321, 0.3109, 0.3224, 0.3119,
  ];
  gradient = ["#F44336", "#FF9800", "#4CAF50"];
}
</script>

<style scoped></style>
