<template>
  <v-card height="100%">
    <v-card-title> Alerts </v-card-title>
    <v-card-text>
      <v-list three-line>
        <template v-for="(event, index) in events">
          <v-divider :key="index"></v-divider>
          <v-list-item :key="index">
            <v-list-item-avatar>
              <v-icon :color="event.color">mdi-alert</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ event.title }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ event.text }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "EventsPanel",
})
export default class EventsPanel extends Vue {
  events: any[] = [
    { title: "title 1", text: "lorem ipsum dolor sit amet", color: "warning" },
    { title: "title 2", text: "lorem ipsum dolor sit amet", color: "error" },
    { title: "title 3", text: "lorem ipsum dolor sit amet", color: "success" },
  ];
}
</script>

<style scoped></style>
