<template>
  <v-card>
    <v-card-title> Eterkyd detector Mk. II </v-card-title>
    <v-card-text>
      <v-progress-linear :value="value" color="error" height="24">
        <template>
          <span>{{ message }}</span>
        </template>
      </v-progress-linear>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "ScanProgressPanel",
})
export default class ScanProgressPanel extends Vue {
  @Prop({ required: true })
  value!: number;

  messages = [
    "Initiating quantum spectral scan for airborne toxins...",
    "Engaging nanofilter algorithms to detect volatile anomalies...",
    "Activating bio-neural sensors to isolate harmful particulates...",
    "Commencing multi-phase toxin detection protocol: scanning in progress...",
    "Calibrating molecular resonance filters for toxic gas identification...",
    "Running hyperfrequency scan: identifying hazardous nanoclusters...",
    "Analyzing atmospheric harmonics for anomalous chemical signatures...",
    "Engaging bio-synthetic detectors: scanning for neurotoxic compounds...",
    "Activating interdimensional toxicity probes for cross-realm pollutants...",
    "Initiating zero-point field scan to detect exotic contaminant elements...",
  ];

  get message() {
    return this.messages[
      Math.floor(this.value / (100 / this.messages.length)) %
        this.messages.length
    ];
  }
}
</script>

<style scoped></style>
